import { Link } from "react-router-dom";

export default function Menu({ setMenuOpen, data, setTab, tab }) {
  return (
    <div className="mean-bar">
      <a
        href="#nav"
        className="meanmenu-reveal"
        style={{ right: "0px", left: "auto", display: "inline" }}
      >
        <span>
          <span>
            <span></span>
          </span>
        </span>
      </a>
      <nav className="mean-nav">
        <ul>
          {data.map((elm, i) => (
            <li
              key={i}
              className={` ${i + 1 === data.length && "mean-last"} ${tab === elm.tab && "active"
                }  `}
              style={{ paddingTop: "5px", paddingBottom: "4px" }}
              onClick={() => {
                setTab(elm.tab)
                setMenuOpen(false);
              }}
            >
              <Link href={elm.href}>
                <span>
                  <i
                    style={{ fontSize: "18px", marginRight: "10px" }}
                    className={elm.icon}
                  ></i>
                </span>
                {elm.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
