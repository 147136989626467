"use client";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
// import { portfolioData } from "../../../data/portfolioData";
import React, { useState, useEffect } from "react";
import Modal from "./ModalBlog";

export default function PortfolioThree({ desarrollos }) {
  const { fraccionamientos } = desarrollos?.perfil || [];
  const [filteredItem, setFilteredItem] = useState([]);
  const [activeTab, setActiveTab] = useState("Todas");
  const [modalContent, setModalContent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [filtroCiudades, setFiltroCiudades] = useState([]);
  useEffect(() => {
    if (activeTab === "Todas") {
      setFilteredItem(fraccionamientos);
    } else {
      const filtered = fraccionamientos.filter((elm) =>
        elm.ciudad.includes(activeTab)
      );
      setFilteredItem(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    //Set para almacenar ciudades únicas
    const ciudadesUnicas = new Set(["Todas"]);

    fraccionamientos.forEach(item => {
      ciudadesUnicas.add(item.ciudad);
    });

    const arregloCiudades = Array.from(ciudadesUnicas);
    setFiltroCiudades(arregloCiudades);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="bostami-page-content-wrap">
        <div className="section-wrapper pl-60 pr-60 pt-60">
          <div className="bostami-page-title-wrap mb-15">
            <h2 className="page-title">Desarrollos</h2>
          </div>
        </div>

        <div className="section-wrapper pr-60 pl-60 mb-60">
          <div className="row">
            <div className="col-12">
              <ul className="fillter-btn-wrap buttonGroup isotop-menu-wrapper mb-30">
                {filtroCiudades.map((elm, i) => (
                  <li
                    onClick={() => setActiveTab(elm)}
                    key={i}
                    className={`fillter-btn ${activeTab === elm.text ? "is-checked" : ""
                      } `}
                  >
                    {elm}
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-12">
              <div id="fillter-item-active" className="fillter-item-wrap ">
                <AnimatePresence>
                  <ResponsiveMasonry
                    columnsCountBreakPoints={{
                      350: 1,
                      850: 2,
                      1100: 3,
                      1200: 2,
                    }}
                  >
                    <Masonry>
                      {filteredItem.map((elm, i) => (
                        <motion.div
                          layout
                          initial={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                          transition={{ duration: 0.3 }}
                          key={elm.id}
                          className={elm.class}
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{ width: "100%" }}
                            className={`fillter-item ${elm.bgClass}`}
                          >
                            <span
                              className="img cursor-pointer"
                              data-bs-toggle="modal"
                            // href="/#"
                            >
                              <img
                                width={310}
                                style={{ width: "100%", height: "fit-content" }}
                                height={310}
                                src={elm.banner}
                                alt="portfolio"
                                onClick={() => {
                                  setModalContent(elm);
                                  setShowModal(true);
                                }}
                              />
                            </span>
                            <span className="item-subtitle">
                              {elm.ciudad}
                            </span>
                            <h6
                              className="item-title"
                              onClick={() => {
                                setModalContent(elm);
                                setShowModal(true);
                              }}
                            >
                              <span className="cursor-pointer">{elm.nombre}</span>
                            </h6>
                          </div>
                        </motion.div>
                      ))}
                    </Masonry>
                  </ResponsiveMasonry>
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>


      </div>
      <Modal
        setShowModal={setShowModal}
        showModal={showModal}
        modalContent={modalContent}
      />
    </>
  );
}
