import React from "react";
import notFoundImg from "../../../assets/img/notFound.jpg"

export default function PersonalInfoThree({ profileInfo }) {
  const { perfil = {}, vendedor = {} } = profileInfo?.data || {};
  return (
    <div className="col-xxl-4 col-xl-4 col-lg-4">
      <div className="bostami-parsonal-info-area">
        <div className="bostami-parsonal-info-wrap">
          <div className="bostami-parsonal-info-img">
            <img
              width={240}
              height={240}
              src={perfil.urlFoto || notFoundImg}
              alt="author"
            />
          </div>

          <h4 className="bostami-parsonal-info-name">
            <span><strong>{vendedor.nombre}</strong></span>
          </h4>
          <span className="bostami-parsonal-info-bio mb-15">Asesor</span>

          <ul className="bostami-parsonal-info-social-link mb-30">
            <li>
              <a style={{ color: '#1773EA' }} href={perfil.facebook} target="_blank" rel="noreferrer">
                <i className={"fa-brands fa-facebook-f"}></i>
              </a>
            </li>
            <li>
              <a style={{ color: '#1C9CEA' }} href={perfil.twitter} target="_blank" rel="noreferrer">
                <i className={"fa-brands fa-twitter"}></i>
              </a>
            </li>
            <li>
              <a style={{ color: '#e12a72' }} href={perfil.instagram} target="_blank" rel="noreferrer">
                <i className={'fa-brands fa-instagram'}></i>
              </a>
            </li>
            <li>
              <a style={{ color: '#144679' }} href={perfil.linkedin} target="_blank" rel="noreferrer">
                <i className={'fa-brands fa-linkedin-in'}></i>
              </a>
            </li>
          </ul>
          <div className="bostami-parsonal-info-contact mb-30">
            <div className="bostami-parsonal-info-contact-item">
              <div
                style={{ color: `#E93B81`, fontSize: `22px` }}
                className="icon"
              >
                <i className="fa-regular fa-mobile"></i>
              </div>
              <div className="text">
                <span>Teléfono</span>
                <p>{perfil.telefono}</p>
              </div>
            </div>
            <div className="bostami-parsonal-info-contact-item" style={{ overflowX: 'auto' }}>
              <div
                style={{ color: `#6AB5B9`, fontSize: `22px` }}
                className="icon"
              >
                <i className="fa-regular fa-envelope-open-text"></i>
              </div>
              <div className="text">
                <span>Correo</span>
                <a style={{ color: "#000" }} href={`mailto:${perfil.email}`}>{perfil.email}</a>
              </div>
            </div>
            <div className="bostami-parsonal-info-contact-item">
              <div
                style={{ color: `#FD7590`, fontSize: `22px` }}
                className="icon"
              >
                <i className="fa-solid fa-location-dot"></i>
              </div>
              <div className="text">
                <span>Ubicación</span>
                {
                  perfil?.ubicacionMaps &&
                  <p><a href={perfil.ubicacionMaps} target="_blank" rel="noreferrer">Ver ubicación</a></p>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
