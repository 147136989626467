import ContactForm from "./ContactForm";

export default function ContactThree({ desarrollos, tokenAsesor, pathname }) {

  return (
    <div className="bostami-page-content-wrap">
      <div className="section-wrapper pl-60 pr-60 pt-60">
        <div className="bostami-page-title-wrap mb-15">
          <h2 className="page-title">contacto</h2>
        </div>
      </div>

      <div className="section-wrapper pr-60 pl-60 mb-60">
        <div className="contact-area bg-light-white-2">
          {/* <h5 className="contact-title">I'm always open to discussing produuct</h5>
          <h5 className="contact-title-b">design work or partnerships.</h5> */}
          <ContactForm desarrollos={desarrollos} tokenAsesor={tokenAsesor} pathname={pathname} />
        </div>
      </div>

      <div className="footer-copyright text-center bg-light-white-2 pt-25 pb-25">
        <span>
          (r) {new Date().getFullYear()} - ARIA CRM - Ciudad Maderas
        </span>
      </div>
    </div>
  );
}
