import React from 'react';
import picture404 from "../../../assets/img/404.png";

const Error404Screen = () => {
    return (
        <div
            className="text-white dark:bg-meeting"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <div className="d-flex flex-column">
                {/* flex flex-col items-center text-6xl font-bold w-3/5} */}
                <img src={picture404} alt='imagen 404' />
                <div className='shadow-lg'>Página no encontrada</div>
            </div>
        </div>
    );
}

export default Error404Screen;