import React from 'react';
// import './App.css';
import "./main.scss"
import "./custom.scss";
import Home3 from "../src/app/resumes/resume-3/page.jsx";
function App() {
  return (
    <>
      <Home3 />
    </>
  );
}

export default App;
