import React, { useEffect, useState } from "react";

import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function AboutThree({ profile }) {
  const { fraccionamientos } = profile?.perfil || [];
  const [showSlider, setShowSlider] = useState(false);
  useEffect(() => {
    setShowSlider(true);
  }, []);
  return (
    <div className="bostami-page-content-wrap">
      <div className="section-wrapper pl-60 pr-60 pt-60">
        <div className="bostami-page-title-wrap mb-15">
          <h2 className="page-title">Sobre mi</h2>
          <p>{profile?.perfil.descripcion}</p>
          <p>{profile?.perfil.experiencia}</p>
        </div>
      </div>

      <div className="section-wrapper bg-light-white-2 pt-45 pb-60 pl-60 pr-60">
        <div className="bostami-section-title-wrap text-center mb-50">
          <h3 className="section-title">Fraccionamientos</h3>
        </div>

        <div className="bostami-client-slider">
          <div className="swiper-container client_slide_active">
            {showSlider && (
              <Swiper
                // {...setting}
                modules={[Navigation, Pagination, Autoplay]}
                // loop={true}
                spaceBetween={1}
                slidesPerView={3}
                loop={true}
                autoplay={{
                  delay: 3000, // Time between each slide (in milliseconds)
                  disableOnInteraction: false, // Set to false if you want the auto slider to continue even when the user interacts with the slider (e.g., clicking on a slide).
                }}
                breakpoints={{
                  // when window width is >= 576px
                  450: {
                    slidesPerView: 3,
                  },
                  // when window width is >= 768px
                  768: {
                    slidesPerView: 4,
                  },
                  1200: {
                    // when window width is >= 992px
                    slidesPerView: 5,
                  },
                }}
              >
                {Array.isArray(fraccionamientos) && fraccionamientos.length > 0 && fraccionamientos.map((elm, i) => (
                  <SwiperSlide key={i}>
                    <div className="swiper-slide">
                      <img
                        height={62}
                        width={264}
                        style={{ height: '62px', width: 'fit-content' }}
                        className="bostami-client-slider-logo"
                        src={elm.logo}
                        alt="client"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>

      {/* <div className="footer-copyright text-center pt-25 pb-25">
        <span>
          © {new Date().getFullYear()} All Rights Reserved by elite-themes24.
        </span>
      </div> */}
    </div>
  );
}
