import React from "react";

export default function HeaderThree({ menuItemsThree, tab, setTab }) {

  const handleClickTab = (tabName) => {
    setTab(tabName)
  }
  return (

    <div className="text-right">
      <div className="bostami-main-menu-wrap">
        <nav className="bastami-main-menu main_menu_3">
          <ul>
            {menuItemsThree.map((elm, i) => (
              <li key={i} className={`${tab === elm.tab ? "active text-white" : "text-black"}`}
                style={{ cursor: 'pointer', width: '100px' }}
                onClick={() => handleClickTab(elm.tab)}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center"
                }}
                >
                  <span style={{ fontSize: "24px", marginBottom: "12px" }}>
                    <i className={elm.icon}></i>
                  </span>
                  <div> {elm.text}</div>
                </div>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
