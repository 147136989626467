import React, { useEffect, useState } from "react";
import AboutThree from "../../../components/homes/about/AboutThree";
import HeaderFour from "../../../components/homes/headers/HeaderFour";
import HeaderThree from "../../../components/homes/headers/HeaderThree";
import PersonalInfoThree from "../../../components/homes/personalInfo/PersonalInfoThree";
// import ResumeThree from "../../../components/homes/resume/ResumeThree";
import PortfolioThree from "../.././../components/homes/portfolio/PortfolioThree";
// import BlogsThree from "../../../components/homes/blogs/BlogsThree";
import ContactThree from "../../../components/homes/contact/ContactThree";
import { URLMaderas } from "../../../Helpers/apimaderas";
import { useParams } from 'react-router-dom';

export const metadata = {
  title: "Resume-3 || Bostami - Professional portfolio NextJS Template",
  description:
    "Discover Botami,the most impressive portfolio template for work showcase, blog",
};
export default function Page() {
  const { token: tokenAsesor } = useParams();

  const [tab, setTab] = useState("sobremi");
  const [profile, setProfile] = useState({});
  const menuItems = [
    { id: 1, icon: "fa-light fa-user", text: "Sobre mi", tab: "sobremi" },
    { id: 3, icon: "fa-light fa-briefcase", text: "Desarrollos", tab: "desarrollos" },
    { id: 5, icon: "size-22 fa-light fa-address-book", text: "Contacto", tab: "contacto" },
  ]

  const renderTab = (tab) => {
    const component = {
      "sobremi": <AboutThree profile={profile?.data} />,
      "desarrollos": <PortfolioThree desarrollos={profile?.data} />,
      "contacto": <ContactThree desarrollos={profile?.data?.perfil} tokenAsesor={tokenAsesor} />
    }

    return component[tab];
  }

  useEffect(() => {
    const fetchAPI = async () => {
      try {

        const resp = await fetch(`${URLMaderas}/seller/profile`, {
          method: 'POST',
          headers: {
            // Authorization: 'Basic WkpRUEtaSE5HVTpab0piWUlYdkRjd3FjZW5QWWhTQ2RUS3dqcGdYcmRwdQ==',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "token": tokenAsesor
          })
        }).then((resp) => resp.json());
        if (resp.success) {
          setProfile(resp);
        } else {
          throw new Error(resp.message)
        }
      } catch (error) {
        console.log("Error_getDataProfile:", error);
        window.alert(error)
      }
    }

    fetchAPI();
  }, [])
  return (
    <>
      <div className="page-wrapper home-3">
        <HeaderFour menuItems={menuItems} tab={tab} setTab={setTab} />
        <div className="container z-index-3">
          <div className="row">
            <PersonalInfoThree profileInfo={profile} />
            <div className="col-xxl-8 col-xl-8 col-lg-8">
              <HeaderThree menuItemsThree={menuItems} tab={tab} setTab={setTab} />
              {renderTab(tab)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
